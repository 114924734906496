<template>
  <div>
    <grid-layout
      :layout.sync="layout"
      :col-num="174 + tableWidth"
      :row-height="6"
      :max-rows="77 + tableHeight"
      :is-draggable="true"
      :is-resizable="false"
      :prevent-collision="true"
      :allow-overlay="true"
      :vertical-compact="false"
      :use-css-transforms="false"
      :auto-size="false"
      :margin="[1,1]"
      :style="'background-color: ' + primaryColorDarken + ';'"
    >
      <grid-item
        v-for="item in layout"
        :x="item.x"
        :y="item.y"
        :w="item.w"
        :h="item.h"
        :i="item.i"
        :key="item.i"
        @moved="savePosition(item)"
      >
        {{ item.label }}
      </grid-item>
    </grid-layout>
    <v-col class="text-center">
      <v-btn
        @click="saveMap"
        color="primary"
        x-large
        :disabled="this.editedVariationsObject === null"
      >
        {{ $t('savePosition') }}
      </v-btn>
    </v-col>
  </div>
</template>

<script>
  import { GridLayout, GridItem } from "vue-grid-layout"
  export default {
    name: "LocationVariationMap",
    components: {
      GridLayout,
      GridItem
    },
    props: {
      zone: {
        type: Object,
        required: true
      },
      location: {
        type: Object,
        required: true
      }
    },
    data: () => ({
      variations: null,
      layout: [],
      editedVariationsObject: null,
      tableWidth: 17,
      tableHeight: 8
    }),
    computed: {
      primaryColorDarken() {
        function shadeColor(color, percent) {

          let R = parseInt(color.substring(1,3),16)
          let G = parseInt(color.substring(3,5),16)
          let B = parseInt(color.substring(5,7),16)

          R = parseInt(R * (100 + percent) / 100)
          G = parseInt(G * (100 + percent) / 100)
          B = parseInt(B * (100 + percent) / 100)

          R = (R<255)?R:255
          G = (G<255)?G:255
          B = (B<255)?B:255

          const RR = ((R.toString(16).length==1)?"0"+R.toString(16):R.toString(16))
          const GG = ((G.toString(16).length==1)?"0"+G.toString(16):G.toString(16))
          const BB = ((B.toString(16).length==1)?"0"+B.toString(16):B.toString(16))

          return "#"+RR+GG+BB
        }
        return shadeColor(this.$store.getters.primaryColor, -40)
      },
    },
    methods: {
      savePosition: function(variation) {
        if(this.editedVariationsObject === null || typeof  this.editedVariationsObject === 'undefined') {
          this.editedVariationsObject = {}
        }
        this.editedVariationsObject[variation.uuid] = variation
      },
      saveVariation: function({item, settings}) {
        const variation = item
        const formattedVariation = {
          uuid: variation.uuid,
          label: variation.label,
          zone: variation.zone.uuid,
          location: this.location.uuid,
          x: variation.x * 10,
          y: variation.y * 10,
          minimumPersons: variation.minimumPersons,
          maximumPersons: variation.maximumPersons,
          standardPersons: variation.standardPersons
        }

        return this.$store.dispatch('updateLocationVariation', {locationVariation: formattedVariation}).then(res => {
          if(res !== null && typeof res !== 'undefined') {
            return {
              success: true,
              message: this.$t('successfullyUpdated')
            }
          } else {
            return {
              success: false,
              message: this.$t('somethingWentWrong')
            }
          }

        })
      },
      saveMap: function() {
        const editedVariations = Object.values(this.editedVariationsObject)

        this.$store.commit('updateBadgeProcess', {
          isVisible: true,
          process: this.saveVariation,
          items: editedVariations,
          onFinish: function() { return }
        })
      }
    },
    beforeMount() {
      this.variations = this.location.variations.filter(variation => variation.zone !== null && variation.zone !== undefined && variation.zone.uuid === this.zone.key)
      for(let i = 0; i < this.variations.length; i++) {
        let variation = JSON.parse(JSON.stringify(this.variations[i]))

        if(variation.x === null || variation.x === undefined) {
          variation.x = 0
        }
        if(variation.y === null || variation.y === undefined) {
          variation.y = 0
        }
        variation.x = Math.floor(variation.x / 10)
        variation.y = Math.floor(variation.y / 10)
        variation.w = this.tableWidth
        variation.h = this.tableHeight
        variation.i = i

        this.layout.push(variation)
      }
    }
  }
</script>

<style scoped>
  .vue-grid-layout {
    touch-action: none;
    height: 600px;
  }
  .vue-grid-item:not(.vue-grid-placeholder) {
    background: #ffffff88;
    border: 2px solid #000;
    text-align: center;
    padding-top: 2px;
  }
</style>
